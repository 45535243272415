<template>
  <k-container
    class="tw-transition-colors tw-duration-200"
    :class="{ 'tw-bg-white tw-text-gray-900': variant === 'light', 'tw-bg-gray-900 tw-text-white': variant === 'dark' }"
  >
    <nav aria-label="Primárna navigácia"
         class="tw-flex tw-justify-between tw-items-center tw-transition-colors tw-duration-200 tw-h-20"
    >
      <div class="tw-flex tw-items-center tw-space-x-6">
        <k-link
          :to="{ name: 'Home' }"
          :class="{ 'tw-text-gray-700': variant === 'light', 'tw-text-white': variant === 'dark' }"
        >
          <k-logo class="tw-h-10 sm:tw-h-12 tw-object-left"/>
        </k-link>

        <div class="tw-hidden xl:tw-flex tw-items-center">
          <k-link
            v-for="(link, idx) in primaryLinks"
            :to="link.to"
            :key="idx"
            class="tw-py-0.5 tw-px-3 tw-rounded-md tw-mr-2 tw-text-base tw-leading-relaxed tw-font-medium tw-transition-colors tw-duration-200 tw-bg-transparent"
            :class="{ 'tw-text-gray-800, hover:tw-bg-gray-100': variant === 'light', 'tw-text-white': variant === 'dark' }"
            type="other"
          >
            {{ link.title }}
          </k-link>

          <k-menu v-if="!routeLocale" :label="$t('global.actions.more')" label-class="hover:tw-bg-gray-100">
            <k-link :to="{ name: 'Questionaire' }"
                    class="tw-text-gray-800 tw-text-base tw-leading-7 tw-no-underline hover:tw-text-gray-500"
            >
              {{ $t('navigation.adequate_salary') }}
            </k-link>

            <k-link :to="{ name: 'WorkComparison' }"
                    class="tw-text-gray-800 tw-text-base tw-leading-7 tw-no-underline hover:tw-text-gray-500"
            >
              {{ $t('navigation.work_types_comparison') }}
            </k-link>

            <k-link :to="{ name: 'dictionary-index' }"
                    class="tw-text-gray-800 tw-text-base tw-leading-7 tw-no-underline hover:tw-text-gray-500"
            >
              {{ $t('navigation.dictionary') }}
            </k-link>

            <k-link :to="{ name: 'event-index' }"
                    class="tw-text-gray-800 tw-text-base tw-leading-7 tw-no-underline hover:tw-text-gray-500"
            >
              {{ $t('navigation.events') }}
            </k-link>

            <div class="tw-max-w-full tw-h-px tw-bg-gray-200">
              <!-- Divider -->
            </div>

            <k-link :to="{ name: 'AboutUs' }"
                    class="tw-text-gray-800 tw-text-base tw-leading-7 tw-no-underline hover:tw-text-gray-500"
            >
              {{ $t('navigation.about_us') }}
            </k-link>

            <k-link :to="{ name: 'AboutUs', hash: '#contact' }"
                    class="tw-text-gray-800 tw-text-base tw-leading-7 tw-no-underline hover:tw-text-gray-500"
            >
              {{ $t('navigation.contact_us') }}
            </k-link>

            <k-link :to="{ name: 'KoderiaAbout' }"
                    class="tw-text-gray-800 tw-text-base tw-leading-7 tw-no-underline hover:tw-text-gray-500"
            >
              {{ $t('navigation.what_is_koderia') }}
            </k-link>
          </k-menu>
        </div>
      </div>

      <div class="xl:tw-hidden">
        <v-btn icon @click="$emit('open-drawer')">
          <k-icon name="menu" class="tw-text-gray-700"/>
        </v-btn>
      </div>

      <div class="tw-hidden xl:tw-flex tw-flex tw-items-center">
        <k-language-nav-switcher v-if="!profile" class="tw-mr-2" label-class="language-switch-label"/>

        <span class="tw-relative">
          <span
            class="tw-top-1/2 tw-left-0 tw-transform tw--translate-y-1/2 tw-h-6 tw-absolute"
            :class="{ 'tw-border-gray-100': variant === 'light', 'tw-border-gray-700': variant === 'dark', 'tw-border-l': !profile }"
          ></span>
        </span>

        <k-nav-user style="isolation: isolate"
                    class="tw-z-20 tw-relative tw-ml-6"
                    @open-drawer="$emit('open-drawer')" :variant="variant"
        />
      </div>
    </nav>
    <slot></slot>
  </k-container>
</template>

<script>
import KLanguageNavSwitcher from '@web/components/core/k-language-nav-switcher'
import KNavUser from '@web/components/core/k-nav-user'
import KMenu from '@web/components/core/k-menu'
import { getRouteLocale } from '@web/constants/language'
import { mapGetters } from 'vuex'

export default {
  components: { KMenu, KNavUser, KLanguageNavSwitcher },
  props: {
    hasBorder: {
      type: Boolean,
      default: true
    },
    scrolled: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'light',
      validator: function (value) {
        return ['light', 'dark'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    ...mapGetters({
      profile: 'USER/getUserProfile'
    }),
    routeLocale() {
      return getRouteLocale()
    },
    primaryLinks() {
      return [
        {
          title: this.$t('navigation.jobs'),
          to: {
            name: 'project-index'
          }
        },
        {
          title: this.$t('global.my_resume'),
          to: {
            name: 'profile-cv'
          }
        },
        {
          title: this.$t('navigation.calculator'),
          to: {
            name: 'Calculator'
          }
        },
        {
          title: this.$t('navigation.blog'),
          to: {
            name: 'blog-index'
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .language-switch-label {
  font-size: 0.875rem !important;
}
</style>
