<template>
    <div class="tw-min-h-screen tw-flow-root">
        <div ref="IntersectionObserverRef"></div>
        <div
            v-if="noHeader !== true"
            style="isolation: isolate"
            class="tw-sticky tw-top-0 tw-z-20"
            @open-drawer="userDrawer = true"
        >
            <k-primary-nav ref="PrimaryNavRef" v-bind="navPropsComputed" @open-drawer="menuDrawer = true">
                <slot name="nav"></slot>
            </k-primary-nav>
        </div>

        <k-mobile-nav :menu-drawer="menuDrawer" :user-drawer="userDrawer" @open-drawer="userDrawer = true; menuDrawer = false" @close-menu-drawer="menuDrawer = false" @close-user-drawer="userDrawer = false" />

        <div id="MainContent" class="tw-h-full">
            <k-secondary-nav v-if="isHP" ref="SecondaryNavRef" />

            <slot></slot>
        </div>
        <k-footer v-if="noFooter !== true" ref="FooterRef" />
    </div>
</template>

<script>
import {DEFAULT_LOCALE} from '@web/constants/language'

export default {
    props: {
        navProps: {
            type: Object,
            default: () => ({
                variant: "light"
            })
        },
        noHeader: {
            type: Boolean,
            default: false
        },
        noFooter: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({ observer: null, scrolled: false, menuDrawer: false, userDrawer: false }),

    computed: {
        navPropsComputed() {
            return {
                ...this.navProps,
                scrolled: this.scrolled,
                variant: this.scrolled ? "light" : this.navProps.variant || "light"
            };
        },
        isHP() {
            return this.$route.name === 'Home'
        },
        onlySK() {
            return this.$store.state.locale === DEFAULT_LOCALE
        }
    },

    mounted() {
        const rootMarginTop = this.$refs.SecondaryNavRef ? this.$refs.SecondaryNavRef.$el.offsetHeight : 0;

        this.observer = new IntersectionObserver(
            entries => {
                const entry = entries[0];
                this.scrolled = !entry.isIntersecting;
            },
            {
                root: null,
                rootMargin: `${rootMarginTop}px 0px 0px 0px`
            }
        );

        this.observer.observe(this.$refs.IntersectionObserverRef);

        this.$nextTick(() => {
            const SecondaryNavRefOffsetHeight = this.$refs.SecondaryNavRef ? this.$refs.SecondaryNavRef.$el.offsetHeight : 0;
            const PrimaryNavRefOffsetHeight = this.$refs.PrimaryNavRef ? this.$refs.PrimaryNavRef.$el.offsetHeight : 0;
            const FooterRefOffsetHeight = this.$refs.FooterRef ? this.$refs.FooterRef.$el.offsetHeight : 0;

            const minHeight = window.innerHeight - SecondaryNavRefOffsetHeight - PrimaryNavRefOffsetHeight - FooterRefOffsetHeight;

            document.getElementById("MainContent").style.minHeight = minHeight + "px";
        });
    },

    destroyed() {
        this.observer.disconnect();
    }
};
</script>
