<template>
    <k-container
        as="nav"
        aria-label="Sekundárna navigácia"
        class="tw-transition-colors tw-duration-200"
        :class="{
            'tw-bg-white tw-text-gray-900': variant === 'light',
            'tw-bg-gray-900 tw-text-gray-50': variant === 'dark'
        }"
    >
        <div
            class="tw-py-3 sm:tw-py-4 tw-transition-colors tw-duration-200"
            :class="{
                'tw-border-t tw-border-gray-100': variant === 'light',
                'tw-border-t tw-border-gray-700': variant === 'dark'
            }"
        >
            <div class="tw-grid tw-grid-cols-1 tw-gap-y-2 md:tw-grid-cols-2 md:tw-gap-x-4 lg:tw-grid-cols-4">
                <k-link
                    v-for="(link, idx) in primaryLinks"
                    :to="link.to"
                    :key="idx"
                    class="tw-font-medium tw-py-3 tw-px-4 tw-rounded-2xl tw-border-2 tw-border-gray-100 tw-flex-auto hover:tw-border-black tw-transition-colors tw-duration-200 tw-flex tw-items-center"
                    type="other"
                >
                    <span class="tw-inline-flex tw-justify-center tw-items-center tw-bg-gray-100 tw-w-10 tw-h-10 tw-rounded-full tw-mr-4">
                        <k-icon :name="link.icon" :size="16" />
                    </span>

                    {{ link.title }}
                </k-link>
            </div>
        </div>
    </k-container>
</template>

<script>
import KIcon from '@web/components/core/k-icon'
export default {
    components: {KIcon},
    props: {
        variant: {
            type: String,
            default: "light",
            validator: function(value) {
                return ["light", "dark"].indexOf(value) !== -1;
            }
        }
    },
    computed: {
        desktopLinks() {
            return [
                {
                    title: this.$t('navigation.adequate_salary'),
                    icon: "calculator",
                    to: {
                        name: "Questionaire"
                    }
                },
                {
                    title: this.$t('navigation.work_comparison'),
                    icon: "list_check",
                    to: {
                        name: "WorkComparison"
                    }
                },
                {
                    title: this.$t('navigation.dictionary'),
                    icon: "book",
                    to: {
                        name: "dictionary-index"
                    }
                },
                {
                    title: this.$t('navigation.events'),
                    icon: "dictionary",
                    to: {
                        name: "event-index"
                    }
                }
            ];
        },
        mobileLinks() {
            return [
                {
                    title: this.$t('navigation.jobs'),
                    icon: "work",
                    to: {
                        name: "project-index"
                    }
                },
                {
                    title: this.$t('navigation.calculator'),
                    icon: "calculator",
                    to: {
                        name: "Calculator"
                    }
                },
                {
                    title: this.$t('navigation.adequate_salary'),
                    icon: "skill",
                    to: {
                        name: "Questionaire"
                    }
                },
            ];
        },
        primaryLinks() {
            return this.isMobile ? this.mobileLinks : this.desktopLinks
        },
        isMobile() {
            return this.$vuetify.breakpoint.mobile
        },
        profile() {
            return this.$store.state.USER.profile;
        },
        user() {
            return this.$store.state.AUTH.user;
        }
    },
};
</script>
